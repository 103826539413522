import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import Loader from "../../../components/Loader/Loader";
import { CustomToast } from "../../../components/CustomToast/CustomToast"
import RecommendedBlogs from "../BlogLandingPage/Components/RecommendedBlogs/RecommendedBlogs";
import ExtraBlogPart from "../BlogLandingPage/Components/ExtraBlogPart";

// icons
import AuthModalLayout from "../../../layout/AuthModalLayout/AuthModalLayout";
import "../Blog.scss"

function BlrFamousFood() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();



    return (
        <div className={"specific-blog-Page"}>
            <Helmet>
                <title>Bangalore Famous Food- Uncover the City’s Delicious Hidden Gems</title>
                <link rel="canonical" href="https://www.nuego.in/blog/bangalore-famous-food" hreflang="en-IN" />
                <meta name="description" content="Discover the best food in Bangalore, from famous food places to authentic dishes. Explore must-try foods, famous food streets, and top things to eat for an unforgettable experience"/>
                <meta name="keywords" content="Bangalore famous food,Bangalore's culinary gems,best things to eat in Bangalore,must-try food in Bangalore"></meta>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            {
                isDesktopOrLaptop ? <AuthModalLayout>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                    </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = () => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [loading, setLoading] = useState(false);



    const dataContent=[
        {
         
        title:"Bangalore Famous Food: Uncover the City’s Most Delicious Hidden Gems",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Hero%20Image.jpg",
        imgAlt:"Bangalore Famous Food: Uncover the City’s Most Delicious Hidden Gems",
        desc: 

`The Silicon Valley of India, Bangalore is not only known for its work culture, but Bangalore famous food comes next on the list. If you’re ready for a culinary expedition, here is the list of 10 must-have foods for Bangalore visitors and foodies. From charming cafes to iconic eateries, the city has a rich culinary heritage that reflects the diversity of India. Good food in Bangalore is not hard to find. whether you’re a foodie on the hunt for the best dosa in Bangalore or someone looking for an intimate dining experience in one of the fine dining places in Bangalore, this city has something for everyone.

Planning a [trip to Bangalore](https://www.nuego.in/blog/one-day-trip-from-bangalore)? In this blog, we will take you on a gastronomic journey through the top 10 famous foods of Bangalore that you simply cannot miss. So, get ready to indulge in the flavorsome delights that this city has to offer.

## Best Food in Bangalore: A Summary
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Bangalore%20Food%20summary.jpg "Thatte podi idli")
### Bangalore Famous Food/Best Food Places in Bangalore
Vidyarthi Bhavan, Mavalli Tiffin Room (MTR), VV Puram Food Street, Bidadi Thatte Idli Stalls, K.C. Das, Shivaji Military Hotel

### Recommended Savory Foods to Try at the Bangalore Famous Food Places
Benne Dosa, Akki roti, Rava Idli, Thatte Idli, Puliyogare, Fish Curry, Kharabath, Chitranna

### Recommended Desserts to Try at the Bangalore Popular Food Places
Mysore Pak, Obattu, Rasgulla, Chiroti, Kalaadi  

![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Vidyarthi%20Bhavan.jpg "Vidyarthi Bhavan")

If you are looking for humble dishes that have been enjoyed and passed on through generations and are quintessentially Bangalore, every item on this list is a must-try.

### 1. Benne Dosa
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Benne%20Dosa.jpg "Benne Dosa")
Unlike the thin-crust dosas of Tamil Nadu, Benne Dosa, is a crispy, golden crepe which is the most authentic Banglore food you can find. Soft on the inside, it is baked with gobs of butter to get that golden-brown crunchiness on the outside. It is typically served with coconut chutney and a generous dollop of butter to enhance the flavor. For the greatest experience, visit the Central Tiffin Room (CTR) on Margosa Road, near K.G. General Hospital. This legendary diner has been serving this delectable dosa variation for over six decades, assuring you receive a true taste of one of  Bangalore's culinary gems! 

### 2. Akki Roti
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Akki%20Roti.jpg "Akki Roti")
One of the best things to eat in Bangalore is akki roti, a beloved traditional dish, made from rice flour and often mixed with onions, chilies, and dill leaves for a flavorful twist. Soft yet crunchy, it pairs well with coconut chutney, spicy pickles, or veggie curry. The city has various places to eat the greatest akki roti, including MTR (Mavalli Tiffin Room) for a classic dish, Halli Mane in Malleswaram for a real village-style experience, and Shivaji Military Hotel for a spicy, butter-rich version. Whether in a classic eatery like Brahmin's Tiffin Room or a rustic location like Kadamba Veg, akki roti in Bangalore is a must-try for foodies.

### 3. Thatte Idli
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Thatte%20Idli.jpg "Thatte Idli")
Thatte idli is a soft, fluffy, and plate-shaped variation of the traditional South Indian idli, loved for its larger size and unique texture. Brahmin’s Thàtte Idli restaurants are a popular food spot in Bangalore to have the Idli. Thatte idli is sold in various quick-service restaurants around the state including restaurants located on the state highways between Bengaluru and Mysuru. This idli is usually available during breakfast hours and evening hours.

### 4. Bisi Bele Bath
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Bisi%20Bele%20Bath.jpg "Bisi Bele Bath")
Bisi Bele Bath, a quintessential Karnataka dish, is a delicious one-pot meal featuring fragrant rice, lentils, colorful veggies, and aromatic spices. Often topped with ghee and served with crispy papad, it’s both nutritious and comforting. In Bangalore, experiencing Bisi Bele Bath is a must. Renowned spots like Vidyarthi Bhavan and Brahmin's Coffee Bar serve exceptional versions of this classic dish, showcasing the city's rich food culture. Both restaurants are perfect for anyone looking to enjoy some of the best food in Bangalore.

### 5. Mangalorean Fish Curry
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Mangalorean%20Fish%20Curry.jpg "Mangalorean Fish Curry")
Talking about Bangalore famous food, Bangalore's close relationship to the seaside town of Mangalore results in a mouthwatering variety of seafood dishes. The Mangalorean Fish Curry is particularly noteworthy. This tangy and aromatic curry is cooked with fresh fish, coconut, and a variety of tasty spices, reflecting Karnataka's coastal cuisine. To sample the real flavors of Mangalorean Fish Curry, visit prominent seafood eateries in Bangalore, such as Fishland. 

### 6. Rava Idli
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Rava%20Idli.jpg "Rava Idli")
The culinary heritage of Namma Bengaluru, the city of innovation, is extensive. Additionally, MTR, one of its well-known restaurants, was instrumental in developing the distinctive dish known as Rava Idli. When rice was scarce during World War II, the origins of Rava Idli can be traced back to those times. It is a popular breakfast option not only in Bengaluru but all over the nation because it is not only tasty but also provides a satisfying and nourishing meal.On the Bangalore famous dish list, the fermented rice and lentil batter is made fluffy and delicious by combining semolina, yogurt, and spices. Sambar and the dip of coconut chutney enhance its flavor. To increase the nutritional value, you can also include carrots and other vegetables. 

### 7. Filter Coffee
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Filter%20Coffee.jpg "Filter Coffee")
Let’s forget about Bangalore famous food for a second and talk about Bangalore’s filter coffee for a moment. Bangalore is known for its fragrant filter coffee, and its residents take their coffee very seriously. This robust and flavorful beverage, which is made with a blend of dark-roasted coffee beans and served in a traditional stainless steel tumbler with Dabara, is a real South Indian treat. Enjoy a genuine and flavorful brew that embodies Bangalore's coffee culture by immersing yourself in the rich tradition of filter coffee at well-known coffee shops like Indian Coffee House.

### 8. Puliyogare
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Puliyogare.jpg "Puliyogare")
You can't choose favorites among best food in Bangalore as a foodie, but we're confident you won't be able to resist Puliyogare (Tamarind Flavoured Rice). It would be an understatement to describe this famous Karnataka treat as only rice with tamarind flavor. With the ideal balance of tamarind, spices, and sesame oil, it's a symphony of flavors. Typically served with papad and curds. Savor Puliyogare, a must-try for any foodie, at Puliyogare Point, N.R. Colony, Basavanagudi.

### 9. Obattu
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Obattu.jpg "Obattu")
Obattu, famously known as Puran Poli or Holige, is a Bangalore-famous dish and a beloved sweet treat in Karnataka cuisine. This soft, golden-brown flatbread is stuffed with a delicious filling of jaggery and lentils or coconut, making it a delightful balance of sweetness and texture. A must-try food in Bangalore, obattu is often enjoyed with a dollop of _ghee_ or served as a festive dessert. Iconic spots like MTR, Vidhyarthi Bhavan, and local sweet shops ensure you savor the best Obattu in the city.

### 10. Mysore Pak
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Bangalore%20Famous%20Food/Mysore%20Pak.jpg "Mysore Pak")
To end on a sweet note, a visit to Bangalore's famous food scene wouldn't be complete without savoring Mysore Pak. _Ghee_, sugar, and besan combine to create this mouthwatering treat, which has a rich, buttery texture and a tantalizing scent. Its charm is irresistible to sweet lovers! Visit well-known confectionery stores like Sri Krishna Sweets or C. Krishnaiah Chetty & Sons for a genuine taste.

In conclusion, Bangalore's culinary landscape is a vibrant tapestry woven from its rich cultural heritage and diverse flavors. Each dish tells a story, reflecting the city's history and the passion of its people for good food. Whether you’re savoring the buttery goodness of Benne Dosa, the comforting warmth of Bisi Bele Bath, or the tanginess of Mangalorean Fish Curry, every bite offers a unique taste of Bangalore. So, the next time you find yourself in this bustling city, be sure to embark on this Bangalore famous food adventure and explore the hidden gems that make Bangalore’s food scene truly remarkable. Happy eating!

Looking for things to do near Bangalore? Make sure to check [this blog](https://www.nuego.in/blog/weekend-getaways-from-bangalore)!
`       
        }
        ]

    

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <Link to="/blog"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0 '}>blog</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>bangalore-famous-food</p>
                    </div>
                    }
                    <div className="containers d-flex flex-column">
                        <div className="main-blog-extra-blog">
                            {
                                dataContent.map((item, indx) => {
                                    return (
                                        <div key={indx} className="main-blog-card">
                                            <h1>{item.title}</h1>
                                            <img src={item.img} alt={item.imgAlt} />
                                            <div className="blog-content">
                                                <ReactMarkdown>{item.desc}</ReactMarkdown>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <ExtraBlogPart dataContentTitle={dataContent?.title} />
                        </div>
                        <RecommendedBlogs />
                    </div>
                </div>
            }
            <Footer />
        </>
    );
}

export default BlrFamousFood