import graphql from "babel-plugin-relay/macro";

const GetPostBookingDataQuery = graphql`
  query GetPostBookingDataQuery($bookingId: String!) {
    getBookingById(bookingId: $bookingId) {
      id
      rescheduleCount
      passengerdetailSet {
        name
        age
        gender
        seatNo
        seatType
      }
      sourceCityName
      sourceCityId
      destinationCityName
      destinationCityId
      dateOfJourney
      pickupName
      pickupTime
      dropTime
      dropName
      referenceNo
      routeId
      pnrNo
      busNumber
      seatNo
      totalAmount
      seatType
      paymentType
      paymentStatus
      paymentId
      gstInvoice
      duration
      gstAmount
      totalPayable
      getTotalPaidAmount
      totalBaseFare
      qrCode
      expectedDropDatetime
      cancellationTransactionId
      cancellationCharges
      totalRefundedAmount
      isRefunded
      isReviewed
      initialPaymentDate
      initialPaymentTime
      savedEmissions
      greenMilesEarned
      isInsuranceApplied
      itsDiscount
      discountAmount
      cashbackAmount
      insuranceCharge
      greenCashPromoAmount
      walletAmount
      contact {
        bookingContactDetails {
          contact {
            phone
          }
        }
      }
    }
  }
`;
export default GetPostBookingDataQuery;
