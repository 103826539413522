import React, {useEffect, useState} from 'react'
import './OfferListPageDesktop.scss'
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import { MDBModal, MDBModalBody, MDBModalHeader} from 'mdbreact';
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router";
import uuid from "react-uuid";
import ReactMarkdown from "react-markdown";
//imports
import {connect} from 'react-redux'
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";
import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import TermsConditionsDetail
    from "../../components/TermsConditionsModalContent/TermsConditionsDetail/TermsConditionsDetail";
import BottomTrayComponent from "../../components/BottomTrayComponent/BottomTrayComponent";
import {offerStaticData} from "../../utils/staticOfferSliderData";
import Footer from "../../components/Footer/Footer";
// import LuckyDrawerWinnnerCard from "../Home/component/LuckyDrawerWinnnerCard";
// icons
import copyIcon from "../../assets/icons/copy-icon.svg"
import tealCloseIcon from "../../assets/icons/teal-close.svg"
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import  AnyPageFAQ  from '../../components/AnyPageFAQ/AnyPageFAQ';
const SeoFooter = React.lazy(()=>import('../Home/component/SeoFooter/SeoFooter'))
// assets
const card1 = process.env.REACT_APP_CDN_LINK + `assets/images/card1.png`
const super_offe = process.env.REACT_APP_CDN_LINK + `assets/images/super_offer.png`
const emptyOfferIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/empty-illustration.png`



const faqArray = [
    {
        question: "Does NueGo have app-only offers for bus ticket booking?",
        answer: "Yes, NueGo has bus booking offers specific to the NueGo website and the NueGo app, which are not available on other platforms. You can avail these offers on the easy-to-use NueGo app available on the Google Play Store for free. Apart from offers, users also get Green Miles every time they book a ticket and can use a travel checklist feature."
    },
    {
        question: "How can I apply a discount coupon code while booking a bus ticket on NueGo?",
        answer: "For a hassle-free booking process, there is a section dedicated to 'coupons' on the payment page, where you can choose from existing coupon codes to ensure you don’t miss out on offers. You can avail any of the bus booking discount coupons and use them to get discounts."
    },
    {
        question: "What is the current new user discount offered by NueGo?",
        answer: "You can avail the new user discount offered by NueGo by using the Green Miles you accumulate on every trip. Unlike a coupon code for bus booking, Green Miles is available for a longer time, and you can keep accumulating it for every trip. You can also use the NueGo referral option to earn Green Miles for each person you invite to use NueGo."
    },
    {
        question: "Can I combine multiple discount codes for a single bus ticket booking?",
        answer: "It is not possible to combine multiple discount codes for a single bus ticket booking on NueGo. However, there are year-round bus booking discount offers that allow you to get some discount on bus booking with a NueGo coupon code."
    },
    {
        question: "I am a student. Does NueGo offer any student discounts?",
        answer: "NueGo currently does not offer any student discounts for bus booking. However, it is best to keep an eye out for new discounts and offers on the NueGo Website and App under the 'Electrifying Offers' section, as NueGo regularly introduces interesting new discounts and bus travel offers."
    }
];


const content = `
## Bus Ticket Offers
&nbsp;  
Whenever you book a bus ticket online with NueGo, we have a variety of bus booking offers available to help you save money. You can take advantage of these bus booking offers by putting the promo codes into the designated slot when booking your tickets, and you will notice a significant reduction in bus fares.
 
Green Miles is a popular offer on NueGo where customers can earn green miles on every trip for all our bus routes which is valid for 3 months after which these bus ticket booking offers expire. The more a customer travels with NueGo, the more green miles a customer earns, the more discounts he / she gets for future trips. Guests can also access our NueGo Lounge in select cities before traveling.
 
## Online Bus Ticket Booking Offers at NueGo
&nbsp;  
NueGo has a variety of bus booking offers available at a particular time. You can always earn Green Miles discount on bus booking after every trip. "RETURN10," is another one of the bus ticket offers and popular NueGo coupon code which gives you 10% off of all return trips. You can utilize the referral discount as well, which allows you to invite your friends to NueGo and give them and you each 50 Green Miles. Many bus ticket offers other than the year round bus booking discount coupons are also available and keep changing every month. Some promo codes include “CHAMPION” celebrating the new movie Chandu Champion. These bus travel offers provide upto a 10% discount on bus booking on NueGo. Most of the offers available are dynamic and are subject to continuous change. Please refer to the “Offers” section of the website for the latest discounts on NueGo. Also, have a look at our travel itineraries to know more about which places to visit during your trip.
 
## Grab Various Offers on Bus Tickets Booking At NueGo
&nbsp;  
New users on NueGo can avail a coupon code for bus booking constantly as offers on NueGo are perpetually present. Most of these offers provide a great discount on bus booking. Refer the coupon section on NueGo app or the website when you book your bus tickets to avail the latest offer. New users can also use the “RETURN10” bus ticket booking coupons to get another 10% bus booking discount. Users also receive green miles cashback which is credited to their wallets within 48 hours of booking the tickets and is one of the most popular bus ticket offers. Also, depending on the credit card, debit card or bank account users use to book tickets, they can also avail bank bus booking offers specific to the bank or card used.`

const OfferListPageDesktop = (props) => {

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const navigate = useNavigate();
    const {authTypeFromStore} = props
    const [offerList, setOfferList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [bottomTray, setBottomTray] = useState("");
    const [activeModal, setActiveModal] = useState(false);
    const [activeData, setActiveData] = useState({});

    useEffect(() => {
        fetch(`${process.env.REACT_APP_CMS_PROD_API }api/offers?populate=deep`)
            .then((res) => res.json())
            .then((res) => {
                // res.data.sort((a,b)=>{
                //     return Number(a?.attributes?.rank)-Number(b?.attributes?.rank)
                // });
                setOfferList(res.data);
                setLoading(false);
            })
            .catch((err) => {
                if(err){
                    cogoToast.error(
                        <CustomToast
                            heading={"Error"}
                            message={err[0]?.message || err.toString()}
                            type={"error"}
                        />,
                    );
                }
                setOfferList([]);
                setLoading(false);
            })
        return () => {
        };
    }, []);

    let pageResponsiveConstants

    if (isDesktopOrLaptop) {
        pageResponsiveConstants = {
            mainText: "open-600w-16s-24h",
            mainTextHighlighter: "ubuntu-700w-20s-32h",
            expiresOnText: "open-600w-14s-26h mb-0",
            couponCode: "open-600w-14s-22h mb-0",
            emptyOfferHeader: "open-700w-16s-24h mb-3 black-1E1E26-color",
            emptyOfferText: "open-400w-16s-22h margin-bottom-32 grey-2-78787d-color"
        }
    } else {
        pageResponsiveConstants = {
            mainText: "open-600w-11s-18h",
            mainTextHighlighter: "ubuntu-700w-18s-22h",
            expiresOnText: "open-600w-9s-18h mb-0",
            couponCode: "open-600w-14s-22h mb-0",
            emptyOfferHeader: "open-700w-16s-22h mb-3 black-1E1E26-color",
            emptyOfferText: "open-400w-12s-18h grey-2-78787d-color"
        }
    }

    const copyToClipboardHandler = (value) => {
        if (value !== null) {
            navigator.clipboard.writeText(value);
            cogoToast.success(<CustomToast type={"success"} message={"Copied to clipboard"}
                                           heading={"Success"}/>, {position: "top-center"})
        } else {
            cogoToast.error(<CustomToast type={"error"} message={"Something went wrong, Unable to get the Coupon"}
                                         heading={"Error"}/>, {position: "top-center"})
        }

    }
    const redirectToHome = () => {
        navigate('/', {replace: false})
    }

    
    const offerModalPopup = (data) => {
        setActiveModal(true)
        setActiveData(data.attributes)

    }

    const OfferListingSection = () => {
        return loading ?
            <Loader/>
            :
            <div className={'py-4 d-grid card-listing-wrapper flex-wrap-10 mb-1'}>
                {offerList.length > 0 && offerList.sort((a, b) => a?.attributes?.rank - b?.attributes?.rank).map((item, id) => {
                    const isNotExpired = new Date(item.attributes.expiry).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
                    if (isNotExpired)
                    {
                    return (<div key={id}  className={`offer-parent-card`} >
                        <img className={item?.attributes?.tnc ? 'cursor-pointer image-card ': 'image-card'}  onClick={() => offerModalPopup(item)} src={item?.attributes?.offer_bg_image?.url} alt={item?.attributes?.offer_bg_image?.altText}/>
                        
                        {item?.attributes?.iconAltText &&  item?.attributes?.secondaryLabelAltText &&
                        <div className='overlap' style={{  backgroundColor : `${item?.attributes?.secondaryLabels}`}}
>
                            <div className='desc'>
                                <p className='head'>{item?.attributes?.iconAltText}</p>
                                <p className='descrip'>{item?.attributes?.secondaryLabelAltText}</p>
                            </div>
                            { item?.attributes?.code &&
                            <div className='code-n-copy'>
                                <p className='code' >Use Code</p>
                                <div className="copy-code-div">
                                    <img className="cursor-pointer" onClick={() => copyToClipboardHandler(item?.attributes?.code)} src={copyIcon} alt="" />
                                    <p className="mb-0" >{item?.attributes?.code}</p>
                                </div>
                            </div>
                            }   
                    </div>}
                    </div>)
                }
                })}
                {
                    
                        offerList.length === 0 &&
                    <div className={'empty-offer-ilustration d-flex justify-content-center w-100'}>
                        <div className={'text-center'}>
                            <img src={emptyOfferIllustration} alt={'no-offers'} className={''}/>
                            <p className={pageResponsiveConstants.emptyOfferHeader}>No Active Offers</p>
                            <p className={pageResponsiveConstants.emptyOfferText}>Coming soon with some exciting
                                offers
                                for you</p>
                            {isDesktopOrLaptop && <button className={'submit-button text-center'}
                                                          onClick={redirectToHome}
                            ><p> Go To Home</p></button>}
                        </div>
                    </div>
                }
                {activeData.tnc && <div className={'offer-modal-component'}>
                <MDBModal isOpen={activeModal} position={'center'} centered={true}
                          size={"lg"}className="m-auto" toggle={()=>setActiveModal(false)} >
                    <MDBModalBody>
                        <div className="offer-modal-body d-flex flex-column align-items-center">
                        <img className={'close-icon-offer icon-16'} src={tealCloseIcon} alt={'alt'} onClick={() => setActiveModal(false)} /> 
                        <div className="upper-section">
                            <h3 className="offer-heading">{activeData?.title}</h3>
                            <h4 className="offer-sub-heading">{activeData?.description}</h4>
                            { activeData?.code && <div className="copy-coupon-div">
                                <img className="cursor-pointer" onClick={()=>copyToClipboardHandler(activeData?.code)} src={copyIcon} alt="" />
                                <p className="mb-0" >{activeData?.code}</p>
                            </div>
                            }
                            <p className="validity">{`Hurry! Offer expires on ${activeData?.expiry}`}</p>
                        </div> 
                        <div className="tnc-div">
                        <ReactMarkdown>{activeData?.tnc}</ReactMarkdown>
                        </div>
                        </div>
                        
                    </MDBModalBody>
                </MDBModal>
            </div>}
            </div>

    }

    return (
        <div>
            {
                isDesktopOrLaptop ?
                    <AuthModalLayout>
                    <Helmet>
                    <title>Bus Ticket Booking Offers, Discount and Coupons | NueGo</title>
                    <link rel="canonical" href="https://www.nuego.in/bus-booking-offers" hreflang="en-IN" />
                    <meta name="description" content="Save big with bus booking offers online from NueGo. Find discounts, coupons, rewards  and deals. Find the some of the most cheap bus deal online with us. Book Now!"/>
                    <meta name="keywords" content="bus booking offers,bus ticket booking offers,bus ticket offers,bus booking discount,discount on bus booking,online bus booking offers,bus booking discount coupons,bus ticket booking coupons,coupon code for bus booking,NueGo coupon code"></meta>
                    <meta name="robots" content="index,follow"/>
                    <script type='application/ld+json'>
                        {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "url": "https://www.nuego.in/bus-booking-offers",
                        "name": "NueGo Offers",
                        "description": "Discover the travel itineraries for some of the popular destinations. Make the most of your trips with detailed travel plans, including the best time and places to visit in India.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "NueGo",
                            "logo": {
                            "@type": "ImageObject",
                            "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                            "width": 200,
                            "height": 100
                            }
                        }
                        })}
                        </script>
                    </Helmet>
                    <div className={'navbar-wrapper black-1E1E26-bg margin-bottom-100'}>
                                <Navbar fixedNavbar={true}/>
                            </div>
                        <div className={'offer-list-page '}>

                            <div className=" breadcrumber bread-crumb d-flex py-4">
                                    <Link to={"/"}><p className={'open-600w-14s-22h grey-2-78787d-color mb-0  cursor-pointer'}>Home</p></Link>
                                    <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                                    <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Offers</p>
                                    <script type='application/ld+json'>
                                        {JSON.stringify({
                                        "@context": "https://schema.org",
                                        "@type": "BreadcrumbList",
                                        "itemListElement": [
                                            {
                                            "@type": "ListItem",
                                            "position": 1,
                                            "name": "Home",
                                            "item": "https://www.nuego.in"
                                            },
                                            {
                                            "@type": "ListItem",
                                            "position": 2,
                                            "name": "Offers",
                                            "item": "https://www.nuego.in/bus-booking-offers"
                                            }
                                        ]
                                        })}
                                    </script>
                            </div>
                            <div className={'white-fold white-fold-padding'}>                                                  
                                <div className={'inner-div'}>
                                    <h1 className={'ubuntu-700w-32s-48h'}> Offers</h1>
                                    {/* <LuckyDrawerWinnnerCard/> */}
                                    <OfferListingSection/>
                                </div>

                            </div>
                            <AnyPageFAQ faqArray={faqArray} heading={'FAQ'} width={'100%'}/>
                            <SeoFooter heading={'Exciting Bus Ticket Booking Offers, Discounts & Coupons'} content={content}/>
                            
                        </div>
                        <Footer/>
                    </AuthModalLayout>
                    :
                    <MobileSubScreenLayout title={"Offers"} back={() => navigate(-1)}>
                        <div className={'offer-list-page '}>
                        <div className=" mx-auto px-3 bread-crumb d-flex">
                                    <Link to={"/"} ><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                                    <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                                    <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Offers</p>
                        </div>
                            <div className={''}>
                                {/* <LuckyDrawerWinnnerCard/> */}
                                <OfferListingSection/>
                            </div>

                            <AnyPageFAQ faqArray={faqArray} heading={'FAQ'} width={'100%'} />
                            <SeoFooter heading={'Exciting Bus Ticket Booking Offers, Discounts & Coupons'} content={content}/>
                            
                        </div>
                        <Footer/>
                    </MobileSubScreenLayout>
            }
        </div>

    )
}

const mapStateToProps = state => ({
    authTypeFromStore: state.AuthReducer.type
})

export default connect(mapStateToProps)(OfferListPageDesktop)
