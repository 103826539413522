import React, {useEffect, useState} from 'react'
import './style.scss'
// imports
import {connect} from "react-redux";
import {setAuthType, setLoginDataSubmission, setSignupDataSubmission} from "../../store/Actions/AuthActions";
import {Login, Signup} from "../../services/api/DataUpdating";
import GoogleAuthMutation from "../../services/mutation/GoogleAuthMutation";
import {useNavigate} from "react-router";
import {store} from "../../index";
import {setTermsConditionsModalAction} from "../../store/Actions/CommonAction";

// icons
import cross from "../../assets/icons/white-cross-icon.svg"
import { useAuthContext } from '../../hooks/context/auth-context';
import TurnstileWidget from '../TurnstileWidget/TurnstileWidget';
import cogoToast from "cogo-toast";
import {CustomToast} from "../CustomToast/CustomToast";
import CustomButton from '../CustomButton';
const logo_desktop =
  process.env.REACT_APP_CDN_LINK + `assets/images/Logologo_desktop.svg`;

const SignInMobile = (props) => {
    const {
        setTypeToStore, setLoginDataToStore, setSignUpDataToStore,
        signInOperation,
    } = props
    const navigate = useNavigate()

    const {
        activeTab, setActiveTab,  setInputDirty, inputDirty, valid, setValid,setOtpValue
    } = useAuthContext()

    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [showError, setShowError] = useState(false);
	const [showCaptcha, setShowCaptcha] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (activeTab === 'signin') {
            window.Moengage.track_event("signin_page_viewed", {
                "platform": "mobile website",
                "sign_in_origin": `${window.location.href}`
            });
        } else {
            window.Moengage.track_event("signup_page_viewed", {
                "platform": "mobile website",
                "sign_in_origin": `${window.location.href}`
            });
        }


    }, [activeTab]);
    let moeId = localStorage.getItem("moeID");

    const dataSubmission = (captchaToken) => {

        window.Moengage.track_event("login_attempt", {
            account_id: `${moeId === null ? "" : moeId}`,
            platform: `website`,
            page_viewed: "yes",
            mobile_number: `${number === null ? "" : number}`,
        });
		let loginData;
		localStorage.setItem("resendOtpState", 0);
		if (number.length === 10) {
			setShowError(false);
		
			if (activeTab === "signup") {
				loginData = {
					fullName: name,
					mobileNumber: number,
                    captchaToken: captchaToken,
				};
				Signup(
					loginData,
					() => {
                        setOtpValue("");
						setTypeToStore("m-otp");
					},
					() => {
						setShowCaptcha(false);
                        setIsLoading(false)
                        setValid(true)
					},
				);
				setLoginDataToStore(loginData);
			} else {
				loginData = {
					mobileNumber: number,
					captchaToken: captchaToken,
				};

				Login(
					loginData,
					() => {
                        setOtpValue("");
						setTypeToStore("m-otp");
					},
					() => {
						setShowCaptcha(false);
                        setIsLoading(false)
                        setValid(true)
					},
				);
				setSignUpDataToStore(loginData);
			}
		} else {
			setShowError(true);
			setShowCaptcha(false);
		}
	};

    const handleInpuChange = (e) => {
        // setTyping(true)

        // if (e.target.value.length < 11) {
        //     setNumber(e.target.value)
        //     setInputDirty(inputDirty + 1)
        // }

        // if ((e.target.value.length >= 10 && !valid || parseInt(e.target.value[0]) > 5) && inputDirty > 0) {

        //     setValid(true)
        //     setShowError(false)
        // } else {
        //     setShowError(true)
        //     setValid(false)
        // }

        if(e.target.value.length>10) return
		const pattern = /^[6-9]\d{9}$/;
		setNumber(e.target.value);
		if (pattern.test(e.target.value)) {
			setValid(true);
			setShowError(false);
			setInputDirty(inputDirty + 1);
		} else {
			setValid(false);
			setShowError(true);
		}


    }

    const responseGoogle = (response) => {

        if (response.accessToken) {
            let variable = {
                accessToken: response.accessToken
            }
            GoogleAuthMutation(variable, function (response) {
                if (response.googleAuth?.token !== null && response.googleAuth?.token !== null) {
                    localStorage.setItem("token", response.googleAuth.token)
                    localStorage.setItem("refreshToken", response.googleAuth.refreshToken)
                    let loginData = {
                        "fullName": response.googleAuth.user.fisrtName + " " + response.googleAuth.user.lastName,
                        "mobileNumber": response.googleAuth.user.mobileNumber
                    }

                    setLoginDataToStore(loginData)
                }
            }, function (err) {
                if(err){
                    cogoToast.error(
                        <CustomToast
                            heading={"Error"}
                            message={err[0]?.message || err.toString()}
                            type={"error"}
                        />,
                    );
                }
            });
        }
    }

    const termsAndConditionHandler = () => {
        store.dispatch(setTermsConditionsModalAction(true));
    }

    const getCurrentPage = () => {
        let url = window.location.href;
        let currentPage = url.split("/").pop();
        if (currentPage === "profile-detail") {
            setTypeToStore("")
            navigate("/")
        } else {
            setTypeToStore("")
        }
    }

    return (
        <div className={' m-auto pb-5 '}>
            <div className={'login-bg px-4'}>
                {/*<p className={'open-600w-14s-22h teal-22BBB0-color text-right margin-bottom-32'}*/}
                {/*   onClick={() => setTypeToStore("")}>Continue as Guest</p>*/}
                <div className={'d-flex justify-content-end w-100'} onClick={() => getCurrentPage()}>
                    <img src={cross} alt={'alt'} className={'icon-16'}/>
                </div>
                <p className={'open-400w-16s-22h white-color mb-1'}>Welcome to</p>
                <img
                src={logo_desktop}
                alt={"alt"}
                className={"cursor-pointer"}
                onClick={() => navigate("/", { replace: false })}
                />
                {/* <p className={'ubuntu-700w-32s-48h mb-1 white-color'}>NUE<span className={'teal-22BBB0-color'}>GO</span>
                </p> */}
            </div>
            <div className={'bottom-padding-section mobile-login-illustration-bg height-75vh'}>

                {/*/!* name *!/*/}
                {/*{activeTab === 'signup' && <><p className={'open-600w-14s-22h grey-53565A-color mb-1'}>Enter Name</p>*/}
                {/*	<div className={'d-flex common-input-wrap align-items-center mb-4'}>*/}
                {/*		/!*<p className={'open-600w-16s-24h mb-0'}>+91</p>*!/*/}
                {/*		/!*<hr className={'vertical-divider mx-3 my-0'}/>*!/*/}
                {/*		<input type={'text'} className={'input-box w-100'} placeholder={'Enter your Name'}*/}
                {/*		       onChange={e => setName(e.target.value)}*/}
                {/*		/>*/}
                {/*	</div>*/}
                {/*</>}*/}
                {/* number */}

                <div
                    className={'d-flex  align-items-center ' + (showError ? " common-input-wrap-error mb-2 p-3  " : " common-input-wrap mb-4 ")}>
                    <p className={'open-600w-16s-24h mb-0'}>+91</p>
                    <hr className={'vertical-divider mx-3 my-0'}/>
                    {/*<input type={"text"} className={'input-box w-100'} placeholder={'Enter your number'}*/}
                    {/*       onChange={e=>setNumber(e.target.value)}*/}
                    {/*/>*/}

                    <div>
                        {number.length > 0 && <p className={'open-600w-12s-18h opacity-60 mb-1'}>Enter Phone Number</p>}
                        <input
                            type="number"
                            pattern="[0-9]*"
                            autoFocus
                            min="0"
                            maxLength={9}
                            value={parseInt(number)}
                            placeholder={'Enter your number'}
                            onKeyDown={(e) => {
								if (e.key === "Enter") {
									setShowCaptcha(true)
								}
							}}
                            onChange={(e) => handleInpuChange(e)}
                            onInput={(e) => handleInpuChange(e)}
                            className={'input-box open-600w-16s-24h w-100'}
                        />
                    </div>
                </div>
                {showError && <div>
                    <p className={'open-600w-16s-24h'}>Invalid Mobile Number</p>
                </div>}

                <CustomButton
                    ctaName={"Get OTP"}
                    disabled={!valid} 
                    onClick={() => {
						setShowCaptcha(true)
						setValid(false)
                        setIsLoading(true)
					}}  
                    isLoading={isLoading}
                 />

                {showCaptcha && (
						<TurnstileWidget
							onSuccess={(args) => dataSubmission(args)}
							forceCaptcha={false}
						/>
					)}
                <p className={'open-400w-12s-18h opacity-60'}>You may receive SMS updates from NueGo and can opt out
                    at
                    any time.</p>
                {/*<div className={'d-flex mb-4 align-items-center'}>*/}
                {/*	<hr className={'left-gradiant-line'}/>*/}
                {/*	<p className={'open-400w-14s-22h text-center w-fit-content opacity-60 mb-0 mx-3 whitespace-nowrap w-100 mb-0'}>or*/}
                {/*		continue*/}
                {/*		with</p>*/}
                {/*	<hr className={'right-gradiant-line'}/>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*	className={'google-wrap mb-4'}>*/}
                {/*	<GoogleLogin*/}
                {/*		clientId={googleClientId}*/}
                {/*		buttonText="Signup with Google"*/}
                {/*		onRequest={(args) => console.log('request', args)}*/}
                {/*		onScriptLoadFailure={(err) => console.log('script load failure', err)}*/}
                {/*		onSuccess={(args) => responseGoogle(args)}*/}
                {/*		onFailure={(args) => responseGoogle(args)}*/}
                {/*	/>*/}
                {/*</div>*/}

                <p className={'open-600w-12s-18h teal-22BBB0-color mb-0 text-center cursor-pointer'}
                   onClick={() => termsAndConditionHandler("Terms & Conditions")}
                >Terms &
                    Conditions</p>

            </div>

        </div>
    )
}

const mapDispatchToStore = dispatch => ({
    setTypeToStore: data => dispatch(setAuthType(data)),
    setSignUpDataToStore: data => dispatch(setSignupDataSubmission(data)),
    setLoginDataToStore: data => dispatch(setLoginDataSubmission(data)),
})

export default connect(null, mapDispatchToStore)(SignInMobile)
