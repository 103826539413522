import React from 'react'
import "./CardGrid.scss"
import { Link , useNavigate} from "react-router-dom"
import {useMediaQuery} from "react-responsive";
import {data} from "../data"

function CardGrid({category,categoryData}) {
    
const navigate = useNavigate();
const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
    });
  return (
    <div className='parent-div'>
        <h2 className='category-name'>{category}</h2>
        <div className='card-main-div'>
            {
                data.filter(el=>el.section==category).map((item,index)=>(
                    <div className="card">
                        <img onClick={()=>navigate(item.route)} className="card-img-top cursor-pointer" src={isDesktopOrLaptop ? item?.img : item?.mwebImg} alt="Card image cap" />
                            <div className="card-body">
                            <Link to={item.route}><h5 className="card-title">{item.title}</h5></Link>
                            <p className="card-text">{item.desc}</p>
                            <Link to={item.route} className="blog-link">Read More &gt; </Link>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default CardGrid