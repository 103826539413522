import React from 'react'
import SearchBarMweb from '../../../../components/SearchBarMweb/SearchBarMweb'
import { data } from './data'
import { Link } from 'react-router-dom'
import {useMediaQuery} from "react-responsive";

function ExtraBlogPart({dataContentTitle}) {

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

  return (
    <div className="extra-blog">
    <div className="outer-main-card">
            <div className="inner-card">
                <div className="more-on-and-view-all">
                    <h2 className="extra-title">Latest Blogs</h2>
                    {/* <p className="view-all" >View All &gt;</p> */}
                </div>
                <div className="extra-blog-outer">
                    {
                    data.filter(el=>el.title !== dataContentTitle).sort(() => 0.5 - Math.random()).slice(0, 4).map((el,ind)=>{

                    return<div className="extra-blog-div">
                    <img src={el?.mwebImg} alt="" />
                    <div className="mweb">
                        <h3>{el?.title}</h3>
                    <Link to={el?.route}>Read More &gt;</Link>
                    </div>
                        </div>
                    })

                    }
                
                </div>
            </div>
        
    </div>
    <SearchBarMweb/>
    </div>  
  )
}

export default ExtraBlogPart