import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import React, { useEffect, useState } from 'react'
import { useSwiper } from 'swiper/react';
import 'swiper/css/navigation';
import "./Environments.scss"
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import Loader from "../../components/Loader/Loader";
import FallBack from "../../components/FallBack";
import { Helmet } from "react-helmet";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";

//assets
const nextnavigation = process.env.REACT_APP_CDN_LINK + `assets/images/nextnavigation.png`
const environmentnaturevehicle = process.env.REACT_APP_CDN_LINK + `assets/images/environmentnaturevehicle.png`
const trees = process.env.REACT_APP_CDN_LINK + `assets/images/trees.png`
const emission = process.env.REACT_APP_CDN_LINK + `assets/images/emission.png`
const text = process.env.REACT_APP_CDN_LINK + `assets/images/text.png`
const waste = process.env.REACT_APP_CDN_LINK + `assets/images/waste.png`
const noise = process.env.REACT_APP_CDN_LINK + `assets/images/noise.png`
const eco = process.env.REACT_APP_CDN_LINK + `assets/images/eco.png`
const treesforDS = process.env.REACT_APP_CDN_LINK + `assets/images/treesforDS.png`
const right = process.env.REACT_APP_CDN_LINK + `assets/images/right.png`
const left = process.env.REACT_APP_CDN_LINK + `assets/images/left.png`
const wasteDS = process.env.REACT_APP_CDN_LINK + `assets/images/wasteDS.png`
const emissionforDS = process.env.REACT_APP_CDN_LINK + `assets/images/emissionforDS.png`
const naturevehicleforDS = process.env.REACT_APP_CDN_LINK + `assets/images/naturevehicleforDS.png`
const textforDS = process.env.REACT_APP_CDN_LINK + `assets/images/textforDS.png`
const noiseDS = process.env.REACT_APP_CDN_LINK + `assets/images/noiseDS.png`
const ecoDS = process.env.REACT_APP_CDN_LINK + `assets/images/ecoDS.png`


const EnvironmentPage = () => {

    const [LoadingSpiner, setLoadingSpiner] = useState({ value: true, Response: "pending" })
    const [LoadingSpinerBenefits, setLoadingSpinerBenefits] = useState({ value: true, Response: "pending" })
    const [EnvironmentData, setEnvironmentData] = useState([])
    const [EnvironmentBenefitsData, setEnvironmentBenefitsData] = useState([])
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    const navigate = useNavigate();
    const backHandler = () => {
        navigate(-1)
    }

    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }

    const EnvironmentGetApi = () => {
        setLoadingSpiner(pendingObject)

        // ---------- environment-pages Api integration ----------

        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/environment-pages?populate=*`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpiner(errorObject)
                    } else {
                        setLoadingSpiner(sucessObject)
                        setEnvironmentData(res?.data)
                    }
                    return res;
                })

            .catch((err) => {
                setLoadingSpiner(errorObject)
                return err;

            });
    }

    const EnvironmentBenefitsGetApi = () => {
        setLoadingSpinerBenefits(pendingObject)
        // ----- environment-benefits Api integration ----------
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/environment-benefits?populate=*`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpinerBenefits(errorObject)
                    } else {
                        setLoadingSpinerBenefits(sucessObject)
                        setEnvironmentBenefitsData(res?.data)
                    }
                    return res;
                })
            .catch((err) => {
                setLoadingSpinerBenefits(errorObject)
                return err;
            });
    }


    useEffect(() => {
        EnvironmentGetApi();
        EnvironmentBenefitsGetApi();
    }, [])


    return ( isDesktopOrLaptop?(<AuthModalLayout>
        <Helmet>
            <title>Environmentally Friendly Buses | NueGo</title>
            <link rel="canonical" href="https://www.nuego.in/environment" hreflang="en-IN" />
            <meta name="description" content="At NueGo, we're committed to environmental conservation. By promoting environmentally friendly buses, we've saved more than 3,000 trees in a month and reduced emissions." />
            <meta name="keywords" content="environmentally friendly bus,environmentally friendly bus company"></meta>
            <meta name="robots" content="index,follow"/>
            <script type='application/ld+json'>
                {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "WebPage",
                "url": "https://www.nuego.in/environment",
                "name": "NueGo - Environment",
                "description": "At NueGo, we're committed to environmental conservation. By promoting environment friendly buses, we've saved more than 3,000 trees in a month and reduced emissions.",
                "publisher": {
                    "@type": "Organization",
                    "name": "NueGo",
                    "logo": {
                    "@type": "ImageObject",
                    "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                    "width": 200,
                    "height": 100
                    }
                }
                })}
            </script>
        </Helmet>
        <div className={'environment-page'}>
            {/* navbar */}
            {isDesktopOrLaptop && <div>
                <Navbar />
            </div>}
            <div className={'white-fold mt-0 ' + (isDesktopOrLaptop ? "mb-lg-0" : "")}>

                <div className={'EnviromentPages-section'}>
                    <div className={'EnviromentPage'}>
                        <div className={'titles'}>
                            <div className={'nextnavigation'}>
                                <img src={nextnavigation} alt={'nextnavigation'} className={'nextnavigation'}
                                    onClick={backHandler} />
                            </div>
                            <div className={'Environment-title-text'}>Environment</div>
                            <div className={'Environment-title-text-ds'}>Home / <span>Environment</span></div>
                            <script type='application/ld+json'>
                                {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "Home",
                                    "item": "https://www.nuego.in"
                                    },
                                    {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "Environment",
                                    "item": "https://www.nuego.in/environment"
                                    }
                                ]
                                })}
                            </script>
                        </div>
                        <div className={'green-environment'}>
                            <div className={'environmentnaturevehicle'}>
                                <img src={environmentnaturevehicle} alt={'environmentnaturevehicle'}
                                    className={'environmentnaturevehicleimage'} />
                            </div>
                            <div className={'text-area'}>
                                <h1 className={'green-environment-text'}>
                                    See how <span>NueGo</span> is contributing towards Green <span>Environment</span>
                                </h1>
                                <a href={"https://greencellmobility.com/static/media/GCM-ESMS-policy.bb1e36b555757e78dcdb.pdf#toolbar=0"}
                                    target={"_blank"} rel={"noreferrer"}
                                >
                                    <button className={'green-btns'}>
                                        Download Report
                                    </button>
                                </a>
                            </div>
                            <div className={'naturevehicleforDS'}>
                                <img src={naturevehicleforDS} alt={'naturevehicleforDS'}
                                    className={'naturevehicleforDSimage'} />
                            </div>
                        </div>
                    </div>
                    {
                        LoadingSpiner?.value == true ? <Loader /> :

                            <div className={'Enviroment-trees-section'}>
                                <div className={'Enviroment-trees-sectionDS'}>
                                    {LoadingSpiner?.Response != "error" ? EnvironmentData?.map((items, id) => {
                                        return <div className={'tree'} key={id}>
                                            <img src={items?.attributes?.image} alt={'trees'} className={'trees'} />
                                            <img src={treesforDS} alt={'treesforDS'} className={'treesforDS'} />
                                            <div className={'Enviroment-trees-date'}>
                                                {/* June 2022 */}
                                                {items?.attributes?.month} {items?.attributes?.year}
                                            </div>
                                            <div className={'Enviroment-trees-count'}>
                                                {items?.attributes?.treesSaved}
                                            </div>
                                            <div className={'Enviroment-trees-save'}>
                                                TreeS saved
                                            </div>
                                        </div>
                                    })
                                        : <>
                                            <div className={'tree'}>
                                                <img src={trees} alt={'trees'} className={'trees'} />
                                                <img src={treesforDS} alt={'treesforDS'} className={'treesforDS'} />
                                                <div className={'Enviroment-trees-date'}>
                                                    June 2022
                                                </div>
                                                <div className={'Enviroment-trees-count'}>
                                                    500
                                                </div>
                                                <div className={'Enviroment-trees-save'}>
                                                    TreeS saved
                                                </div>
                                            </div>

                                            <div className={'tree'}>
                                                <img src={trees} alt={'trees'} className={'trees'} />
                                                <img src={treesforDS} alt={'treesforDS'} className={'treesforDS'} />
                                                <div className={'Enviroment-trees-date'}>
                                                    Sep 2022
                                                </div>
                                                <div className={'Enviroment-trees-count'}>
                                                    2400
                                                </div>
                                                <div className={'Enviroment-trees-save'}>
                                                    TreeS saved
                                                </div>
                                            </div>

                                            <div className={'tree'}>
                                                <img src={trees} alt={'trees'} className={'trees'} />
                                                <img src={treesforDS} alt={'treesforDS'} className={'treesforDS'} />
                                                <div className={'Enviroment-trees-date'}>
                                                    Dec 2022
                                                </div>
                                                <div className={'Enviroment-trees-count'}>
                                                    3260
                                                </div>
                                                <div className={'Enviroment-trees-save'}>
                                                    TreeS saved
                                                </div>


                                            </div>
                                        </>}

                                </div>
                            </div>}


                    {
                        LoadingSpinerBenefits?.value == true ? <Loader /> :

                            <div className={'Enviroment-advantage-section'}>

                                {LoadingSpinerBenefits?.Response != "error" ?
                                    EnvironmentBenefitsData?.map((items, id) => {

                                        return id % 2 == 1 ? <div className={` Enviroment-ZeroEmission-section`}>
                                            <div>
                                                <div className='emission-image'>
                                                    <img src={items?.attributes?.image} alt={'waste'} className={'waste w-100'} />
                                                </div>
                                                <h2 className='emission-head'>
                                                    {/* <img src={text} alt={'text'} className={'text'} /> */}
                                                    {/* {!isDesktopOrLaptop && <img src={items?.attributes?.image} alt={'textforDS'} className={'textforDS'} />} */}
                                                    {items?.attributes?.title}
                                                </h2>
                                                <div className='emission-para'>
                                                    {items?.attributes?.description}
                                                </div>
                                                {/* <div className='emission-link'>
                                                    Read More
                                                </div> */}
                                            </div>
                                            <div className='emissionforDS-image'>
                                                <img src={items?.attributes?.image} alt={'emissionforDS'} className={'emissionforDS'} />
                                            </div>
                                        </div>
                                            : <div className={'Enviroment-waste-section'}>
                                                <div className='emission-image'>
                                                    <img src={items?.attributes?.image} alt={'waste'} className={'waste w-100'} />
                                                </div>
                                                <div className='emissionforDS-images'>
                                                    <img src={items?.attributes?.image} alt={'wasteDS'} className={'emissionforDS'} />
                                                </div>
                                                <div>
                                                    <div className='emission-head'>
                                                        <img src={text} alt={'text'} className={'text'} />
                                                        {items?.attributes?.title}
                                                    </div>
                                                    <div className='emission-para'>
                                                        {items?.attributes?.description}

                                                    </div>
                                                    {/* <div className='emission-link'>
                                                        Read More
                                                    </div> */}
                                                </div>
                                            </div>
                                    })
                                    : <>
                                        <div className={'Enviroment-ZeroEmission-section'}>
                                            <div className='emission-image'>
                                                <img src={emission} alt={'emission'} className={'emission'} />
                                            </div>
                                            <div>
                                                <div className='emission-head'>
                                                    <img src={text} alt={'text'} className={'text'} />
                                                    <img src={textforDS} alt={'textforDS'} className={'textforDS'} />
                                                    Zero Emission
                                                </div>
                                                <div className='emission-para'>
                                                    Quite like the remote-controlled cars that entertained us as children, an electric
                                                    vehicle (EV) has two main components – a battery and a motor.
                                                    he rest of the running gear is identical to petrol or diesel-driven vehi...
                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                            <div className='emissionforDS-image'>
                                                <img src={emissionforDS} alt={'emissionforDS'} className={'emissionforDS'} />
                                            </div>
                                        </div>

                                        <div className={'Enviroment-waste-section'}>
                                            <div className='emission-image'>
                                                <img src={waste} alt={'waste'} className={'waste'} />
                                            </div>
                                            <div className='emissionforDS-images'>
                                                <img src={wasteDS} alt={'wasteDS'} className={'emissionforDS'} />
                                            </div>
                                            <div>
                                                <div className='emission-head'>
                                                    <img src={text} alt={'text'} className={'text'} />
                                                    E-Waste Management
                                                </div>
                                                <div className='emission-para'>
                                                    E-waste is one of the fastest growing waste streams in the world. While e-waste
                                                    contains
                                                    valuable materials such as aluminium,
                                                    copper, gold, palladium, and silver, it also contains harmful substances like
                                                    cadmium,
                                                    lead, merc...

                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'Enviroment-noise-section'}>
                                            <div className='emission-image'>
                                                <img src={noise} alt={'noise'} className={'noise'} />
                                            </div>
                                            <div>
                                                <div className='emission-head'>
                                                    <img src={text} alt={'text'} className={'text'} />
                                                    No Noise
                                                </div>
                                                <div className='emission-para'>
                                                    Quite like the remote-controlled cars that entertained us as children, an electric
                                                    vehicle (EV) has two main components – a battery and a motor.
                                                    The rest of the running gear is identical to petrol or diesel-driven vehicles. In a
                                                    f...
                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                            <div className='emissionforDS-image'>
                                                <img src={noiseDS} alt={'noiseDS'} className={'emissionforDS'} />
                                            </div>
                                        </div>

                                        <div className={'Enviroment-eco-section'}>
                                            <div className='emission-image'>
                                                <img src={eco} alt={'eco'} className={'eco'} />
                                            </div>
                                            <div className='emissionforDS-images'>
                                                <img src={ecoDS} alt={'ecoDS'} className={'emissionforDS'} />
                                            </div>
                                            <div>
                                                <div className='emission-head'>
                                                    <img src={text} alt={'text'} className={'text'} />
                                                    Eco-system
                                                </div>
                                                <div className='emission-para'>
                                                    E-waste is one of the fastest growing waste streams in the world. While e-waste
                                                    contains
                                                    valuable materials such as aluminium, copper, gold, palladium,
                                                    and silver, it also contains harmful substances like cadmium, lead, merc...

                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }                  
                            </div>
                    }
                                    </div>
            </div>
            <Footer />
        </div>
        </AuthModalLayout>)
        :
        <MobileSubScreenLayout title={"Environment"} back={() => navigate(-1)}>
            <Helmet>
            <title>Environmentally Friendly Buses | NueGo</title>
            <link rel="canonical" href="https://www.nuego.in/environment" hreflang="en-IN" />
            <meta name="description" content="At NueGo, we're committed to environmental conservation. By promoting environmentally friendly buses, we've saved more than 3,000 trees in a month and reduced emissions." />
            <meta name="keywords" content="environmentally friendly bus,environmentally friendly bus company"></meta>
            <meta name="robots" content="index,follow"/>
            <script type='application/ld+json'>
                {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "WebPage",
                "url": "https://www.nuego.in/environment",
                "name": "NueGo - Environment",
                "description": "At NueGo, we're committed to environmental conservation. By promoting environment friendly buses, we've saved more than 3,000 trees in a month and reduced emissions.",
                "publisher": {
                    "@type": "Organization",
                    "name": "NueGo",
                    "logo": {
                    "@type": "ImageObject",
                    "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                    "width": 200,
                    "height": 100
                    }
                }
                })}
            </script>
        </Helmet>
        <div className={'environment-page'}>
            <div className={'white-fold mt-0 ' + (isDesktopOrLaptop ? "mb-lg-0" : "")}>

                <div className={'EnviromentPages-section'}>
                    <div className={'EnviromentPage'}>
                        <div className={'titles'}>
                            <div className={'Environment-title-text'}>Home / <span>Environment</span></div>
                            <script type='application/ld+json'>
                                {JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "Home",
                                    "item": "https://www.nuego.in"
                                    },
                                    {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "Environment",
                                    "item": "https://www.nuego.in/environment"
                                    }
                                ]
                                })}
                            </script>
                        </div>
                        <div className={'green-environment'}>
                            <div className={'environmentnaturevehicle'}>
                                <img src={environmentnaturevehicle} alt={'environmentnaturevehicle'}
                                    className={'environmentnaturevehicleimage'} />
                            </div>
                            <div className={'text-area'}>
                                <h2 className={'green-environment-text'}>
                                    See how <span>NueGo</span> is contributing towards Green <span>Environment</span>
                                </h2>
                                <a href={"https://greencellmobility.com/static/media/EHS-SR-Policy-Rev-Due.d2bd490aebb48ea87f4f.pdf"}
                                    target={"_blank"} rel={"noreferrer"}
                                >
                                    <button className={'green-btns'}>
                                        Download Report
                                    </button>
                                </a>
                            </div>
                            <div className={'naturevehicleforDS'}>
                                <img src={naturevehicleforDS} alt={'naturevehicleforDS'}
                                    className={'naturevehicleforDSimage'} />
                            </div>
                        </div>
                    </div>
                    {
                        LoadingSpiner?.value == true ? <Loader /> :

                            <div className={'Enviroment-trees-section'}>
                                <div className={'Enviroment-trees-sectionDS'}>
                                    {LoadingSpiner?.Response != "error" ? EnvironmentData?.map((items, id) => {
                                        return <div className={'tree'} key={id}>
                                            <img src={items?.attributes?.image} alt={'trees'} className={'trees'} />
                                            <img src={treesforDS} alt={'treesforDS'} className={'treesforDS'} />
                                            <div className={'Enviroment-trees-date'}>
                                                {/* June 2022 */}
                                                {items?.attributes?.month} {items?.attributes?.year}
                                            </div>
                                            <div className={'Enviroment-trees-count'}>
                                                {items?.attributes?.treesSaved}
                                            </div>
                                            <div className={'Enviroment-trees-save'}>
                                                TreeS saved
                                            </div>
                                        </div>
                                    })
                                        : <>
                                            <div className={'tree'}>
                                                <img src={trees} alt={'trees'} className={'trees'} />
                                                <img src={treesforDS} alt={'treesforDS'} className={'treesforDS'} />
                                                <div className={'Enviroment-trees-date'}>
                                                    June 2022
                                                </div>
                                                <div className={'Enviroment-trees-count'}>
                                                    500
                                                </div>
                                                <div className={'Enviroment-trees-save'}>
                                                    TreeS saved
                                                </div>
                                            </div>

                                            <div className={'tree'}>
                                                <img src={trees} alt={'trees'} className={'trees'} />
                                                <img src={treesforDS} alt={'treesforDS'} className={'treesforDS'} />
                                                <div className={'Enviroment-trees-date'}>
                                                    Sep 2022
                                                </div>
                                                <div className={'Enviroment-trees-count'}>
                                                    2400
                                                </div>
                                                <div className={'Enviroment-trees-save'}>
                                                    TreeS saved
                                                </div>
                                            </div>

                                            <div className={'tree'}>
                                                <img src={trees} alt={'trees'} className={'trees'} />
                                                <img src={treesforDS} alt={'treesforDS'} className={'treesforDS'} />
                                                <div className={'Enviroment-trees-date'}>
                                                    Dec 2022
                                                </div>
                                                <div className={'Enviroment-trees-count'}>
                                                    3260
                                                </div>
                                                <div className={'Enviroment-trees-save'}>
                                                    TreeS saved
                                                </div>


                                            </div>
                                        </>}

                                </div>
                            </div>}


                    {
                        LoadingSpinerBenefits?.value == true ? <Loader /> :

                            <div className={'Enviroment-advantage-section'}>

                                {LoadingSpinerBenefits?.Response != "error" ?
                                    EnvironmentBenefitsData?.map((items, id) => {

                                        return id % 2 == 1 ? <div className={` Enviroment-ZeroEmission-section`}>
                                            <div>
                                                <div className='emission-image'>
                                                    <img src={items?.attributes?.image} alt={'waste'} className={'waste w-100'} />
                                                </div>
                                                <h2 className='emission-head'>
                                                    {/* <img src={text} alt={'text'} className={'text'} /> */}
                                                    {/* {!isDesktopOrLaptop && <img src={items?.attributes?.image} alt={'textforDS'} className={'textforDS'} />} */}
                                                    {items?.attributes?.title}
                                                </h2>
                                                <div className='emission-para'>
                                                    {items?.attributes?.description}
                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                            <div className='emissionforDS-image'>
                                                <img src={items?.attributes?.image} alt={'emissionforDS'} className={'emissionforDS'} />
                                            </div>
                                        </div>
                                            : <div className={'Enviroment-waste-section'}>
                                                <div className='emission-image'>
                                                    <img src={items?.attributes?.image} alt={'waste'} className={'waste w-100'} />
                                                </div>
                                                <div className='emissionforDS-images'>
                                                    <img src={items?.attributes?.image} alt={'wasteDS'} className={'emissionforDS'} />
                                                </div>
                                                <div>
                                                    <div className='emission-head'>
                                                        <img src={text} alt={'text'} className={'text'} />
                                                        {items?.attributes?.title}
                                                    </div>
                                                    <div className='emission-para'>
                                                        {items?.attributes?.description}

                                                    </div>
                                                    <div className='emission-link'>
                                                        Read More
                                                    </div>
                                                </div>
                                            </div>
                                    })
                                    : <>
                                        <div className={'Enviroment-ZeroEmission-section'}>
                                            <div className='emission-image'>
                                                <img src={emission} alt={'emission'} className={'emission'} />
                                            </div>
                                            <div>
                                                <div className='emission-head'>
                                                    <img src={text} alt={'text'} className={'text'} />
                                                    <img src={textforDS} alt={'textforDS'} className={'textforDS'} />
                                                    Zero Emission
                                                </div>
                                                <div className='emission-para'>
                                                    Quite like the remote-controlled cars that entertained us as children, an electric
                                                    vehicle (EV) has two main components – a battery and a motor.
                                                    he rest of the running gear is identical to petrol or diesel-driven vehi...
                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                            <div className='emissionforDS-image'>
                                                <img src={emissionforDS} alt={'emissionforDS'} className={'emissionforDS'} />
                                            </div>
                                        </div>

                                        <div className={'Enviroment-waste-section'}>
                                            <div className='emission-image'>
                                                <img src={waste} alt={'waste'} className={'waste'} />
                                            </div>
                                            <div className='emissionforDS-images'>
                                                <img src={wasteDS} alt={'wasteDS'} className={'emissionforDS'} />
                                            </div>
                                            <div>
                                                <div className='emission-head'>
                                                    <img src={text} alt={'text'} className={'text'} />
                                                    E-Waste Management
                                                </div>
                                                <div className='emission-para'>
                                                    E-waste is one of the fastest growing waste streams in the world. While e-waste
                                                    contains
                                                    valuable materials such as aluminium,
                                                    copper, gold, palladium, and silver, it also contains harmful substances like
                                                    cadmium,
                                                    lead, merc...

                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'Enviroment-noise-section'}>
                                            <div className='emission-image'>
                                                <img src={noise} alt={'noise'} className={'noise'} />
                                            </div>
                                            <div>
                                                <div className='emission-head'>
                                                    <img src={text} alt={'text'} className={'text'} />
                                                    No Noise
                                                </div>
                                                <div className='emission-para'>
                                                    Quite like the remote-controlled cars that entertained us as children, an electric
                                                    vehicle (EV) has two main components – a battery and a motor.
                                                    The rest of the running gear is identical to petrol or diesel-driven vehicles. In a
                                                    f...
                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                            <div className='emissionforDS-image'>
                                                <img src={noiseDS} alt={'noiseDS'} className={'emissionforDS'} />
                                            </div>
                                        </div>

                                        <div className={'Enviroment-eco-section'}>
                                            <div className='emission-image'>
                                                <img src={eco} alt={'eco'} className={'eco'} />
                                            </div>
                                            <div className='emissionforDS-images'>
                                                <img src={ecoDS} alt={'ecoDS'} className={'emissionforDS'} />
                                            </div>
                                            <div>
                                                <div className='emission-head'>
                                                    <img src={text} alt={'text'} className={'text'} />
                                                    Eco-system
                                                </div>
                                                <div className='emission-para'>
                                                    E-waste is one of the fastest growing waste streams in the world. While e-waste
                                                    contains
                                                    valuable materials such as aluminium, copper, gold, palladium,
                                                    and silver, it also contains harmful substances like cadmium, lead, merc...

                                                </div>
                                                <div className='emission-link'>
                                                    Read More
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }                  
                            </div>
                    }
                                    </div>
            </div>
            <Footer />
        </div>
        </MobileSubScreenLayout>
    )
}

export default EnvironmentPage;

const Custome_SwiperNAv = ()=> {
    const swiperNAv = useSwiper();
    return (
        <div className={'control'}>
            <div className={'right-arrow'} onClick={() => swiperNAv.slideNext()}>
                <img src={left} alt={'left'} className={'left'} />
            </div>
            <div className={'left-arrow'} onClick={() => swiperNAv.slidePrev()}>
                <img src={right} alt={'right'} className={'right'} />
            </div>
        </div>
    )
}
